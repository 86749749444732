.modal {
  @apply absolute bg-white top-1/2 left-1/2 right-auto bottom-auto max-w-1xl;
  @apply outline-none rounded;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);

  @screen md {
    width: calc(100% - 4rem);
  }

  & :global(.close-button) {
    @apply absolute outline-none;
    @apply flex justify-center items-center z-100;
    @apply right-2 top-2 sm:right-3 sm:top-3 md:-right-5 md:-top-5 w-10 h-10;

    & :global(.circle) {
      @apply bg-black-100 rounded-full text-white cursor-pointer;
      @apply flex justify-center items-center;
      @apply w-10 h-10 md:w-8 md:h-8;

      & :global(.icon) {
        @apply fill-white w-[12px];
      }
    }
  }

  & :global(.content-wrapper) {
    @apply overflow-y-auto;
    max-height: calc(100vh - 3rem);
  }

  & :global(.image-wrapper) {
    @apply w-full max-w-full;
  }

  & :global(figure) {
    @apply aspect-w-4 aspect-h-3 bg-black-100/10;
  }

  & :global(.inner-blocks) {
    @apply p-4 sm:p-6 md:p-8;
  }

  &:global(.large) {
    @apply max-w-8xl;
    & :global(.content-wrapper) {
      @apply flex items-center justify-center flex-col lg:flex-row;

      & :global(.inner-blocks),
      & :global(.image-wrapper) {
        @apply lg:w-1/2;
      }

      & :global(.image-wrapper) {
        @apply lg:p-8;
      }
    }
  }
}
